import React from "react";
import PageContainer from "../components/PageContainer";

const DashboadPage = () => {
  return (
    <PageContainer>
      <h3>Private</h3>
    </PageContainer>
  );
};

export default DashboadPage;
