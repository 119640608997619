import React from "react";
import styled from "styled-components";
import { device, colors } from "../../constants/layout";
import { Spring } from "react-spring/renderprops";

class Logo extends React.Component {
  state = {
    comeBack: false,
  };

  render() {
    const { comeBack } = this.state;
    console.log(
      comeBack,
      "from",
      comeBack ? 0 : 650,
      "to",
      comeBack ? -650 : 0
    );
    return (
      <Svg viewBox="0 0 350 283">
        <Spring
          from={{ x: comeBack ? 0 : 650 }}
          to={{ x: comeBack ? -650 : 0 }}
          config={{ duration: 4000, delay: 1500 }}
          // onRest={() =>
          //   this.setState(({ comeBack }) => ({ comeBack: !comeBack }))
          // }
          reset={comeBack}
        >
          {({ x }) => (
            <Path
              strokeDasharray={650}
              strokeDashoffset={x}
              d="m180.485,2.881c8.536,1.642 16.009,3.972 23.119,7.114c2.506,1.107 4.881,2.399 7.311,3.754c0.304,0.17 0.666,0.196 0.989,0.396c0.62,0.384 1.52,1.052 2.37,1.581c0.139,0.086 0.44,0.083 0.594,0.197c0.09,0.067 0.112,0.341 0.197,0.396c0.306,0.195 0.716,0.213 0.987,0.395c1.116,0.744 2.088,1.592 3.162,2.372c5.221,3.782 9.896,8.128 14.229,13.042c3.102,3.518 6.039,7.629 8.693,11.856c0.087,0.139 0.082,0.439 0.197,0.593c0.066,0.086 0.345,0.115 0.396,0.197c0.165,0.269 0.208,0.662 0.396,0.988c0.146,0.256 0.438,0.514 0.593,0.791c2.194,3.897 4.231,8.262 6.126,12.844c3.594,8.696 6.562,19.039 7.706,30.431c0.336,3.35 0.198,6.939 0.198,10.67c0,57.03 0,117.09 0,174.681c-11.549,-0.936 -21.552,-4.008 -30.036,-8.299c-1.486,-0.752 -2.951,-1.547 -4.347,-2.371c-0.134,-0.079 -0.445,-0.087 -0.594,-0.198c-0.087,-0.065 -0.115,-0.345 -0.197,-0.396c-0.3,-0.183 -0.688,-0.213 -0.988,-0.395c-0.294,-0.18 -0.501,-0.414 -0.791,-0.593c-3.226,-1.997 -6.535,-4.388 -9.484,-6.917c-2.883,-2.472 -5.675,-5.181 -8.299,-8.102c-0.433,-0.482 -0.926,-0.874 -1.383,-1.384c-0.135,-0.149 -0.064,-0.448 -0.198,-0.592c-0.187,-0.2 -0.701,-0.446 -0.987,-0.791c-0.137,-0.163 -0.058,-0.442 -0.198,-0.593c-1.018,-1.093 -2.333,-2.707 -3.359,-4.149c-0.105,-0.148 -0.073,-0.428 -0.197,-0.593c-0.072,-0.097 -0.331,-0.105 -0.396,-0.197c-0.134,-0.189 -0.049,-0.434 -0.198,-0.593c-0.073,-0.08 -0.336,-0.114 -0.395,-0.198c-0.167,-0.24 -0.225,-0.72 -0.395,-0.987c-0.128,-0.2 -0.37,-0.441 -0.594,-0.791c-0.161,-0.252 -0.438,-0.548 -0.593,-0.79c-0.089,-0.14 -0.081,-0.438 -0.196,-0.593c-0.066,-0.088 -0.345,-0.114 -0.396,-0.197c-0.179,-0.288 -0.21,-0.663 -0.396,-0.988c-0.147,-0.259 -0.431,-0.515 -0.592,-0.791c-0.164,-0.278 -0.225,-0.688 -0.396,-0.988c-0.141,-0.244 -0.479,-0.386 -0.593,-0.592c-0.648,-1.181 -1.173,-2.547 -1.779,-3.755c-1.186,-2.366 -2.321,-4.772 -3.359,-7.509c-2.461,-6.498 -4.507,-13.764 -5.532,-21.736c-0.824,-6.412 -0.593,-13.392 -0.593,-21.144c0,-57.072 0,-117.144 0,-174.482c-0.017,-0.281 -0.033,-0.561 0.198,-0.594z"
            />
          )}
        </Spring>

        <Spring
          from={{ x: 450 }}
          to={{ x: 0 }}
          config={{ duration: 4000, delay: 1500 }}
        >
          {({ x }) => (
            <Path
              strokeDasharray={450}
              strokeDashoffset={x}
              d="m5.211,103.856c8.862,1.446 16.767,4.024 23.91,7.311c3.621,1.667 6.861,3.528 10.078,5.533c0.222,0.139 0.23,0.04 0.395,0.198c0.168,0.161 0.427,0.236 0.593,0.395c0.168,0.161 0.427,0.236 0.593,0.396c0.167,0.161 0.427,0.236 0.592,0.395c0.168,0.161 0.427,0.236 0.593,0.395c0.168,0.161 0.427,0.237 0.592,0.396c0.029,0.027 0.184,0.188 0.198,0.198c0.38,0.262 0.842,0.345 1.186,0.592c0.355,0.257 0.625,0.707 0.988,0.988c0.394,0.305 0.806,0.496 1.186,0.791c1.08,0.835 2.046,1.635 3.162,2.569c0.343,0.287 0.627,0.672 0.988,0.988c1.975,1.728 4.023,3.667 5.928,5.73c12.096,13.107 21.253,30.914 25.096,52.562c0.403,2.271 0.731,4.541 0.988,6.917c0.261,2.411 0.198,4.904 0.198,7.509c0,25.138 0,51.778 0,77.46c-11.252,-0.845 -20.785,-3.908 -29.246,-7.904c-0.343,-0.163 -0.663,-0.281 -0.987,-0.593c-0.519,-0.497 -2.136,-0.929 -2.964,-1.383c-0.083,-0.046 -0.127,-0.363 -0.198,-0.396c-0.524,-0.236 -1.527,-0.601 -2.173,-0.988c-0.08,-0.048 -0.13,-0.364 -0.198,-0.395c-1.439,-0.649 -2.964,-1.798 -4.347,-2.768c-0.754,-0.527 -1.642,-1.03 -2.372,-1.58c-0.536,-0.404 -0.982,-0.975 -1.581,-1.383c-0.136,-0.094 -0.448,-0.081 -0.593,-0.198c-0.706,-0.57 -1.272,-1.369 -1.976,-1.976c-0.135,-0.117 -0.275,-0.084 -0.395,-0.197c-2.016,-1.932 -4.689,-4.096 -6.719,-6.324c-0.639,-0.701 -1.095,-1.39 -1.778,-2.174c-0.526,-0.604 -1.18,-1.242 -1.778,-1.976c-0.709,-0.869 -1.371,-1.782 -2.174,-2.767c-1.113,-1.365 -2.467,-3.454 -3.557,-5.138c-6.859,-10.593 -12.512,-24.238 -14.029,-40.113c-0.894,-9.352 -0.396,-19.61 -0.396,-30.628c0,-20.516 0,-41.762 0,-62.047c-0.084,-0.38 0.135,-0.713 0.197,-0.395z"
            />
          )}
        </Spring>

        <Spring
          from={{ x: 450 }}
          to={{ x: 0 }}
          config={{ duration: 4000, delay: 1500 }}
        >
          {({ x }) => (
            <Path
              strokeDasharray={450}
              strokeDashoffset={x}
              d="m170.209,103.856c0,20.351 0,41.663 0,62.244c0,11.097 0.491,21.395 -0.395,30.827c-0.859,9.14 -3.074,17.283 -6.126,24.897c-5.756,14.363 -15.077,26.826 -26.479,35.767c-11.616,9.109 -25.645,15.976 -44.461,17.587c0,-25.776 0,-52.056 0,-77.658c0,-2.605 -0.057,-5.097 0.198,-7.509c1.125,-10.647 3.981,-20.806 7.509,-29.442c0.166,-0.406 0.427,-0.778 0.593,-1.186c0.207,-0.511 0.183,-1.086 0.395,-1.581c0.3,-0.699 0.963,-1.689 1.383,-2.569c0.957,-2.002 1.995,-4.419 3.162,-6.521c0.907,-1.633 1.982,-3.192 2.964,-4.743c0.138,-0.218 0.042,-0.232 0.198,-0.395c0.161,-0.168 0.236,-0.428 0.396,-0.593c0.161,-0.168 0.236,-0.427 0.395,-0.593c0.161,-0.168 0.236,-0.427 0.395,-0.593c0.161,-0.168 0.237,-0.428 0.396,-0.593c0.161,-0.167 0.236,-0.427 0.395,-0.592c0.026,-0.027 0.188,-0.185 0.198,-0.198c0.229,-0.334 0.349,-0.845 0.593,-1.185c0.263,-0.367 0.727,-0.63 0.988,-0.988c0.134,-0.183 0.057,-0.249 0.198,-0.396c0.083,-0.085 0.393,-0.532 0.593,-0.791c12.812,-16.54 30.774,-28.599 55.922,-33.197c0.335,0.043 0.51,-0.336 0.59,0.001z"
            />
          )}
        </Spring>

        <Spring
          from={{ x: 450 }}
          to={{ x: 0 }}
          config={{ duration: 4000, delay: 1500 }}
        >
          {({ x }) => (
            <Path
              strokeDasharray={450}
              strokeDashoffset={x}
              d="m345.482,103.856c0,20.353 0.001,41.649 0,62.244c0,11.084 0.578,21.402 -0.395,30.827c-0.952,9.216 -2.999,17.223 -6.126,24.897c-6.022,14.785 -15.014,26.605 -26.479,35.767c-11.45,9.151 -25.675,15.965 -44.46,17.587c0,-25.752 -0.001,-52.061 0,-77.658c0,-4.979 0.371,-9.729 1.186,-14.228c1.61,-8.911 4.277,-16.968 7.311,-24.7c0.85,-2.166 2.378,-5.078 3.557,-7.311c0.458,-0.867 0.738,-1.749 1.186,-2.569c0.45,-0.824 1.101,-1.558 1.582,-2.371c0.266,-0.451 0.326,-0.936 0.592,-1.383c0.048,-0.081 0.365,-0.129 0.396,-0.198c0.71,-1.574 1.953,-3.458 3.161,-4.94c1.137,-1.394 2.489,-3.378 3.755,-4.94c12.518,-15.453 30.147,-26.473 54.144,-31.023c0.335,0.041 0.509,-0.338 0.59,-0.001z"
            />
          )}
        </Spring>
      </Svg>
    );
  }
}

export default Logo;

const Svg = styled.svg`
  position: absolute;
  width: 80%;
  min-height: 290px;

  height: 80vh;
  z-index: -1;
  opacity: 0.7;
  /* width="350" height="283" */
`;

const Path = styled.path`
  stroke-width: 5px;
  stroke: ${colors.primary};
  fill: none;
`;
